/** @jsx jsx */
import { Link as GatsbyLink } from 'gatsby'
import { jsx } from 'theme-ui'
import Button from './Button'
import ColorModeToggle from './ColorModeToggle'
import Link from './Link'

function Header() {
  return (
    <div>
      <div sx={{ marginX: 'auto', textAlign: 'center', backgroundColor: '#f3eed3', color: '#ef7777', fontSize: 1, lineHeight: 2 }}>
        请注意：FDA.LTD并非美国FDA旗下网站或附属机构
      </div>
      <header
        sx={{
          paddingY: 4,
          paddingX: 5,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Link as={GatsbyLink} to="https://fda.ltd/" sx={{ fontSize: 4 }}>
          FDA助手
        </Link>

        <Button
          as="a"
          href="/releases"
          sx={{
            variant: 'buttons.outline',
            paddingY: 1,
            paddingX: 2,
            marginLeft: 2,
            fontSize: 1,
          }}
        >
          v 1.0
        </Button>

        {/* Spacer */}
        <div sx={{ marginX: 'auto' }} />

        <Link
          href="https://ear-weee.cn"
          target="_blank"
        >
          <span sx={{ color: '#ccc' }}>by </span>
          奥斯曼
        </Link>

        <ColorModeToggle sx={{ marginLeft: 4 }} />
      </header>
    </div>
  )
}

export default Header
